import apiConfig from 'res-api-cli/build/config';
import { getLocalStorageSettingValue } from "settings";
import Honeybadger from "honeybadger-js";

/**
 * Load a value from local storage to override the API URL.
 * To enable the same SPA image to be used to target multiple environments.
 */
const getLocalStorageApiOverride = () => {
  try {
    return window.localStorage.getItem('lumin-api-url-override')
  } catch (e) {
    // Ignore any localStorage issues
    console.log(e);
  }
}

const configureApi = ({ history }) => {
  apiConfig.apiUrl = (() => {
    const apiOverride = getLocalStorageSettingValue("api-url");
    if(apiOverride) {
      return apiOverride;
    }

    switch (window._env_?.REACT_APP_AWS_ENV) {
      case 'academy':
        return 'https://api.forseti-academy.org';
      case 'lumin-sandbox':
        return 'https://api.lumin-sandbox.co.uk';
      case 'staging':
        return 'https://api.os-test.me';
      case 'sandbox':
        return 'https://sandbox-api.os-test.me';
      case 'production':
        return 'https://case-api.ombudsman-services.org';
      default:
        return 'http://localhost:2999';
    }
  })();

  apiConfig.gtmId = (env => {
    switch (env) {
      case 'staging':
        //return 'GTM-5HSTPMN';
        return 'GTM-N9DQHLH';
      case 'sandbox':
        return undefined;
      case 'academy':
        return 'GTM-TTNMQ3R';
      case 'production':
        return 'GTM-NVN24M7';
      default:
        return undefined;
    }
  })(window._env_?.REACT_APP_AWS_ENV);

  apiConfig.onError = (e, context) => {
    switch (e.name) {
      case 'UnauthorizedException':
        history.push('/unauthorized');
        break;
      case 'ForbiddenException':
        history.push('/forbidden');
        break;
      case 'NotFoundException':
        history.push('/forbidden');
        break;
      case 'ValidationException':
        break;
      case 'BunnyPublisherTimeoutException':
        break;
      case 'AuthTokenExpiredException':
        history.push('/signout');
        history.push('/signin');
        break;
      default:
        Honeybadger.setContext(context)
        Honeybadger.notify(e, 'Unhandled API exception');
        history.push('/unavailable');
        break;
    }

    return Promise.reject(e);
  };
};
export default configureApi;
