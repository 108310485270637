/**
 * Global settings to either configure or hide/show individual features.
 *
 * Override locally by adding a local storage item 'override-<<setting.name>>'
 * Settings with type: 'feature' must be set to either true or false
 */
export const settings = [
  { name: "api-url", label: "API URL", type: 'text', localOnly: true },
  { name: "brackets-task-search", label: "Brackets task search", type: "feature", default: true, hidden: true },
  { name: "supplier-api-view", label: "Supplier API view", type: "feature", default: true },
  { name: "rebrand-popup-message", label: "Rebrand popup message", type: "feature", default: false },
  { name: "alternate-pdf-viewer", label: "Alternate PDF Viewer", type: "feature", default: false, roles: [
      'coach',
      'leader',
      'dre-one',
      'dre-two',
      'dre-three',
    ]
  },
  { name: "maintenance-popup-message", label: "Maintenance popup message", type: "feature", default: false },
];

const localStoragePrefix = "override-";

/**
 * Load an override value of the setting from local storage, which can be used for local testing
 * or running integration tests on hidden features.
 * @param settingName
 * @returns {string|boolean}
 */
export const getLocalStorageSettingValue = settingName => {
  const setting = window.localStorage.getItem(localStoragePrefix + settingName);
  if (setting === "true") {
    return true;
  }
  if (setting === "false") {
    return false;
  }
  return setting;
};

/**
 * Set the override value of the setting in local storage
 * @param settingName
 * @param value
 */
export const setLocalStorageSetting = (settingName, value) => {
  window.localStorage.setItem(localStoragePrefix + settingName, value);
};

/**
 * Set the override value of the setting in local storage
 * @param settingName
 */
export const clearLocalStorageSetting = settingName => {
  window.localStorage.removeItem(localStoragePrefix + settingName);
};

/**
 * Calculate if a feature is enabled. Any valid local storage value takes precedence
 * over the setting specified above.
 * @param settingName
 * @returns {boolean|string}
 */
export const featureIsEnabled = settingName => {
  const setting = settings.find(s => s.name === settingName);
  if (setting == null) {
    return false;
  }
  return getLocalStorageSettingValue(settingName) ?? setting.default;
};

/**
 * Remove any unused localStorage items that start with the localStoragePrefix value
 */
export const pruneLocalStorage = () => {
  Object.keys(localStorage).forEach(key => {
    const name = key.replace(localStoragePrefix, "");
    if (key.startsWith(localStoragePrefix) && !settings.find(s => s.name === name)) {
      localStorage.removeItem(key);
    }
  });
};

/**
 * Determine if a user has the role to access a setting
 * @param user current user
 * @param setting - a single settings item
 * @returns {boolean}
 */
export const userCanAccessSetting = (user, setting) => {
  // Hide localOnly settings if not working locally
  if (setting.localOnly) {
    return window.location.hostname === "localhost";
  }

  if (setting.hidden) {
    return false;
  }

  // Always show if no roles are defined
  if (!setting.roles) {
    return true;
  }

  // Don't show if not logged in
  if (!user) {
    return false
  }

  // Show if user has required role
  return Array.isArray(setting.roles) && setting.roles.includes(user.role);
}
